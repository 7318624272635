import {PortalLink} from '@biostrand/components';
import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography} from '@mui/material';
import {Stack} from '@mui/system';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {isFeatureSupported, PortalRuntimeFeatures} from '../../../portalRuntimeConfig';
import {RouteUrl} from '../../../routeUrl';
import JobsOverview from '../settings/jobs/JobsOverview';

const PortalPageContent = (): JSX.Element => {
    const isJobsSupported = isFeatureSupported(PortalRuntimeFeatures.JOBS);
    const [t] = useTranslation();

    return (
        <Stack sx={{ alignItems: 'center',  flex: 1, mt: 2,  overflowY:'scroll' }} spacing={2}>
            {isJobsSupported ? (
                <Stack sx={{width:'90%', maxWidth: 1500,  m: 2, alignItems: 'stretch', justifyContent:"stretch",  flex:1, }} spacing={2}>
                    <Stack direction={'row'} sx={{ alignItems: 'baseline', justifyContent: 'center' }} spacing={2}>
                        <Typography variant={'h4'}>{t('Jobs')} </Typography>
                        <PortalLink url={RouteUrl.JobsHistorySettings} style={{ textDecoration: 'underline' }}>
                            {t('Jobs history')}
                            <FontAwesomeIcon icon={faExternalLink} style={{ marginLeft: 8, fontSize: 16, width: 16 }} />
                        </PortalLink>
                    </Stack>
                    <JobsOverview />
                </Stack>
            ) : (
                'Welcome to portal'
            )}

        </Stack>
    );
};

export default PortalPageContent;
