import {uApi} from '@biostrand/components';
import {useEffect, useState} from 'react';
import {ArticleInfo} from "@biostrand/articlelens/src/app/common/useArticleInfo";

export const useJobType = (props: { jobTypeId: string | undefined }) => {
    const jobTypeId = props;
    const [isLoading, setIsloading] = useState(false);
    const [jobType, setJobType] = useState<ArticleInfo | undefined>(undefined);

    useEffect(() => {
        const runQ = async () => {
            if (jobTypeId) {
                setIsloading(true);
                try {
                    const result = await uApi.getJobManagerApi().jobManagerGetJobType(Number(jobTypeId));
                    setJobType(result.data);
                } finally {
                    setIsloading(false);
                }
            }

        };

        runQ();
    }, [jobTypeId]);


    return [jobType, isLoading];
};
