import {showPopup} from '@biostrand/components/src/popup/popupsSlice';
import {faPlay} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Avatar, Button, Card, Typography} from '@mui/material';
import {Stack} from '@mui/system';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {ProtoJobType} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {faInfoCircle} from "@fortawesome/pro-light-svg-icons";
import JobInfoPopup from "./JobInfoPopup";
import {runJobFlowAction} from "./jobsSlice";

interface JobTypeCardProps {
    template: ProtoJobType;
}

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            height: 48, width: 48, minWidth: 48,
        },
        children: `${name.substring(0,2).toUpperCase()}`,
    };
}

const JobTypeCard = (props: JobTypeCardProps): JSX.Element => {
    const { template } = props;
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const onRunJob = () => {
        dispatch(runJobFlowAction(template));
    };

    const onJobInfo = async () => {
        dispatch( showPopup({
            key: 'job-type-key',
            title: t(`${template.name} job info`),
            content: React.createElement(JobInfoPopup, {
                popupKey: 'job-type-key',
                jobTypeId: template.id || -1,
            }),
        }))
    }
    return (
        <Card sx={{ display: 'flex', m: 1, width: 340 }}>
            <Stack spacing={2} direction={'row'} sx={{ width: 330, m: 2, mb: 1 }}>
                <Stack spacing={1} sx={{ flex: 1 }}>
                  <Stack direction={'row'} spacing={2}>  {template.name && <Avatar {...stringAvatar(template?.name + template?.version)} />}
                    <Stack>
                    <Typography>{template.name}</Typography>
                    <Typography color='text.secondary' variant={'body2'}>
                      {template.version}
                    </Typography>
                    </Stack>
                  </Stack>

                    {template.description && (
                        <Typography
                            component={'div'}
                            variant='body2'
                            color='text.secondary'
                            title={template.description}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 7,
                            }}>
                            {template.description}
                        </Typography>
                    )}
                    <div style={{flex:1, margin:-2}} />
                    <Stack spacing={2} direction={'row'} sx={{ justifyContent: 'flex-end', alignItems: "flex-end", alignSelf: 'stretch' }}>
                        <Button
                            startIcon={<FontAwesomeIcon style={{ marginLeft: 4, fontSize: 16 }} icon={faInfoCircle} />}
                            onClick={onJobInfo}
                            size={'small'}>
                            {t('Info')}
                        </Button>
                        <Button
                            startIcon={<FontAwesomeIcon style={{ marginLeft: 4, fontSize: 16 }} icon={faPlay} />}
                            onClick={onRunJob}
                            size={'small'}>
                            {t('Run job')}
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
};

export default JobTypeCard;
