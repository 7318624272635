import {
  ProtoContractCategory,
  ProtoContractElement,
  ProtoContractGroup,
  ProtoContractType,
  ProtoJobType,
} from '@biostrand/biostrandapi/javascript/dist/JobManagerApi';
import {datasetsSelector, uApi} from '@biostrand/components';
import {DatasetOrFileSelectionField} from '@biostrand/components/src/datasets/pickers/DatasetOrFileSelectionField';
import {faPlay} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {FormikProvider, getIn, useFormik} from 'formik';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import isInteger from 'lodash.isinteger';
import isString from 'lodash.isstring';

import {runJobAction} from "./jobsSlice";
import {FormikHelpers} from "formik/dist/types";
import {LoadingButton} from "@mui/lab";
import {ArrayListSelectionField} from "./ArrayListSelectionField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {SingleDatasetOrFileSelectionField} from "../datasets/pickers/SingleDatasetOrFileSelectionField";
import {DebouncedTextInput} from "../debouncedInput/DebouncedTextInput";
import {useJobType} from "./useJobType";

interface RunJobPopupProps {
  jobTypeId: ProtoJobType;
  showGroupFilter?: boolean;
  selectedGroupId?: string;
}

interface RunJobFormProps {
  template: ProtoJobType;
  showGroupFilter?: boolean;
  selectedGroupId?: string;
}

const isRangeField = (range) => {
  if (range) {
    const {min, max, step} = range;
    return isFinite(Number(min)) && isFinite(Number(max)) && isFinite(Number(step));
  }
  return false
}

const getError = (name: string, {touched, errors, status}) => {
  const fieldTouched = getIn(touched, name);
  const backendError = getIn(status, ["apiErrors", name]);
  const clientError = getIn(errors, name);

  if (clientError && fieldTouched) {
    return clientError;
  }
  if (backendError && !fieldTouched) {
    return backendError;
  }
  return undefined;
};

const createValidationFunction = (groups: ProtoContractGroup[]) => async (values) => {
  const errors = {};

  let allFields = []
  groups?.forEach(group => {
    allFields = allFields.concat(group.fields || []);
  })

  for (let i = 0; i < allFields.length; i++) {
    const param:ProtoContractElement = allFields[i];
    if (param.category === ProtoContractCategory.OUTPUT) continue;
    const paramKey: string = param.name || '';

    if (param.list_element && param.list_element.length > 0) {
      if (param.required && param.multiselect) {
        if (!values[paramKey]) {
          errors[paramKey] = '* At least one element should be selected';
          //  return;
        }
        if (!(Array.isArray(values[paramKey]) && values[paramKey].length > 0)) {
          errors[paramKey] = '* At least one element should be selected';
          //  return;
        }

      }
      continue;
    }

    if (param.validations) {
      const vErrors = []
      param.validations.forEach( validator => {
        if (validator) {
          const r = new RegExp(validator.regex);
          if (!r.test(values[paramKey])) {
            vErrors.push( validator.help_message );
          }
        }
      })
      if (vErrors.length > 0) {
        errors[paramKey] = vErrors.join(', ');
      }
    }

    if (param.required && !values[paramKey]) {
      errors[paramKey] = '*Required';
    }

    if (param.type === ProtoContractType.NUMBER && values[paramKey] && !isFinite(Number(values[paramKey]))) {
      errors[paramKey] = 'Must be a valid number';
    }

    if (param.type === ProtoContractType.INTEGER && values[paramKey] && !isInteger(Number(values[paramKey]))) {
      errors[paramKey] = 'Must be a valid integer';
    }

    if ((param.type === ProtoContractType.NUMBER || param.type === ProtoContractType.INTEGER) && values[paramKey]) {
      if (param.range) {
        const {min, max} = param.range;
        const nValue = values[paramKey]
        if (Number(min) > nValue) {
          errors[paramKey] = `the value must be greater than ${min}`;
        }
        if (Number(max) < nValue) {
          errors[paramKey] = `the value must be greater than ${max}`;
        }
      }
    }

    if (param.type === ProtoContractType.STRING && values[paramKey] && !isString(values[paramKey])) {
      errors[paramKey] = 'Must be a string';
    }

    if (param.type === ProtoContractType.OUTPUT_DATASET_NAME && values[paramKey]){
      const response = await uApi.getDatasetManagerApi().datasetManagerCheckName(values[paramKey]);
      if (response.data?.exist) {
        errors[paramKey] = `Dataset with name "${values[paramKey]}" already exist`;
      }
    }
  }

  console.log('errors => ', errors)
  return errors;
}

const getDefaultValues = (contract) => {
  const initialValues = {};
  contract?.forEach(group => {
    group.fields?.forEach((item: ProtoContractElement) => {
      if (item.category !== ProtoContractCategory.OUTPUT && item.default) {
        const itemKey: string = item.name || '';

        const isMultiplyValueSelector = item.list_element && item.list_element.length > 0 && item.multiselect;
        if (isMultiplyValueSelector) {
          if (item.default) {
            initialValues[itemKey] = Array.isArray(item.default) ? item.default : [item.default];
          }
          return;
        }

        switch (item.type) {
          case ProtoContractType.BOOLEAN:
            initialValues[itemKey] = item.default !== 'false' && item.default  // additional check for the item.default = false,
            break;

          case ProtoContractType.DATASET:
            break; //what can I do here?

          default:
            initialValues[itemKey] = item.default;
            break;
        }
      }
    })
  });
  return initialValues;
};

export const RunJobPopup = ( props: RunJobPopupProps ): JSX.Element => {
  const {jobTypeId, ...rest} = props;

  const [template, isLoading] = useJobType(jobTypeId)

  if (isLoading) return (<Stack sx={{minWidth:300}}><LinearProgress /></Stack>)
  if (!template) return (<Stack sx={{minWidth:300}}>ups <LinearProgress /></Stack>)
  return <RunJobFormPopup template={template} {...rest} />
}

export const RunJobFormPopup = (props: RunJobFormProps): JSX.Element => {
  const {template, showGroupFilter, selectedGroupId} = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const datasets = useSelector(datasetsSelector);
  const contract = template.contract.groups;

  const validator = createValidationFunction(contract || []);

  const mapValues = (values) => {
    const mappedValues = {};

    let allFields = []
    contract?.forEach(group => {
      allFields = allFields.concat(group.fields || []);
    })

    allFields.forEach((element: ProtoContractElement) => {
      const key = element.name || '';

      if (element.category === ProtoContractCategory.OUTPUT) return;

      if (element.list_element && element.list_element.length > 0) {
        mappedValues[key] = values[key];
        return;
      }

      if (element.type === ProtoContractType.BOOLEAN) {
         if (values[key] !== undefined) {
           mappedValues[key] = Boolean(values[key]);
         }
         return;
      }

      if (values[key]) {
        switch (element.type) {
          case ProtoContractType.DATASET:
            const value = values[key][0]
            const [datasetId, ...path] = value.split('/');
            const ds = datasets?.find(d => datasetId === d.id);
            if (ds) {
              path.unshift(ds.name);
            }
            mappedValues[key] = path.join('/');
            break;
          case ProtoContractType.DATASET_INPUTS:
            const mlValue = values[key].map((value) => {
              const [datasetId, ...path] = value.split('/');
              const ds = datasets?.find(d => datasetId === d.id);
              if (ds) {
                path.unshift(ds.name);

              }
              return path.join('/');
            })
            mappedValues[key] = {dataset: mlValue};
            break;
          case ProtoContractType.INTEGER:
            mappedValues[key] = parseInt(values[key]);
            break;
          case ProtoContractType.NUMBER:
            mappedValues[key] = Number(values[key]);
            break;

          default:
            mappedValues[key] = values[key];
            break;
        }
      }
    });
    return mappedValues;
  }

  const formik = useFormik({
    initialValues: getDefaultValues(contract || []),
    validate: validator,
    isInitialValid: false,
    onSubmit: (
      values: any,
      actions: FormikHelpers<any>,
    ) => {
      dispatch(runJobAction({
        jobType: template,
        values: mapValues(values),
        callback: (feedback) => {
          actions.setSubmitting(false);

          if (feedback.resultType === "error") {
            const {value} = feedback;

            if (value.details && value.details[0] && value.details[0].field_violations) {
              const fields = value.details[0].field_violations;

              const apiErrors = {};
              fields.forEach((item) => {
                actions.setFieldTouched(item.field, true, false)
                // actions.setFieldError(item.field, item.description)
                apiErrors[item.field] = item.description;

              })
              actions.resetForm(values);
              actions.setStatus({apiErrors});
            }
          }
        }
      }));
    },
  });

  const renderFields = (fields: ProtoContractElement[]) => {
    return fields.map((param: ProtoContractElement) => {
        if (param.category === ProtoContractCategory.OUTPUT) return;

        const helperTextParts: string[] = [];

        if (param.required) helperTextParts.push(t('*Required'));
        if (param.type === ProtoContractType.ARRAY) helperTextParts.push(t('comma separated values'));
        if (param.description) helperTextParts.push(param.description);

        const helperText = helperTextParts.join(', ');

        const label: string = param.label || param.name || '';
        const placeholder: string = param.placeholder || '';
        const range = param.range;

        const name: string = param.name || '';
        const fError: string | undefined = getError(name, formik);

        const isValueSelector = param.list_element && param.list_element.length > 0;

        if (isValueSelector) {
          return (
            <Stack key={param.name} direction={"row"} spacing={4}>
              <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                <Typography variant={'subtitle2'}>{label}</Typography>
                <ArrayListSelectionField
                  multiselect={param.multiselect}
                  name={name}
                  id={name}
                  key={name}
                  error={fError}
                  options={param.list_element}
                  placeholder={placeholder}
                />
              </Stack>
              <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
            </Stack>
          )
        }
        //
        // if (isRangeField(range)) {
        //   return <Stack key={param.name} direction={"row"} spacing={4}>
        //     <Stack spacing={4} key={name} sx={{width: 600, minWidth: 600}}>
        //       <Typography variant={'subtitle2'}>{label}</Typography>
        //
        //       <SliderInputField field={name}
        //                         valueLabelDisplay="on"
        //                         error={fError} name={name}
        //                         form={formik}
        //                         marks={[
        //                           {value: range.min, label: range.min},
        //                           {value: range.max, label: range.max}
        //                         ]}
        //                         meta={formik.getFieldMeta(name)}/>
        //     </Stack>
        //     <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
        //   </Stack>
        // }

        switch (param.type) {
          case ProtoContractType.DATASET: {
            return (
              <Stack key={param.name} direction={"row"} spacing={4}>

                <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                  <Typography variant={'subtitle2'}>{label}</Typography>
                  <SingleDatasetOrFileSelectionField
                    name={name}
                    id={name}
                    key={name}
                    error={fError}
                    placeholder={placeholder}

                  />
                </Stack>
                <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
              </Stack>
            );
          }
          case ProtoContractType.DATASET_INPUTS: {
            return (
              <Stack key={param.name} sx={{flex: 1, maxWidth: 1200}}>
                <DatasetOrFileSelectionField
                  label={label}
                  labelSize={600}
                  name={name}
                  id={name}
                  key={name}
                  error={fError}
                  placeholder={placeholder}
                  showGroupFilter={showGroupFilter}
                  selectedGroupId={selectedGroupId}
                  description={<Typography variant={"body2"}
                                           sx={{ml: 4, maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>}
                />
              </Stack>
            );
          }
          case ProtoContractType.BOOLEAN:
            return (
              <Stack key={param.name} direction={"row"} spacing={4}>
                <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                  <Typography variant={'subtitle2'}>{label}</Typography>
                  <FormControlLabel
                    sx={{width: 600}}
                    control={
                      <Checkbox
                        name={name}
                        id={name}
                        checked={formik.values[name]}
                        onChange={(event, value) => {
                          // strange behaviour if use on change for checkbox
                          formik.setFieldValue(name, value, true);
                        }}
                      />
                    }
                    label={param.description}
                  />
                </Stack>
                <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
              </Stack>
            );

          case ProtoContractType.OUTPUT_DATASET_NAME:
            return (<Stack key={param.name} direction={"row"} spacing={4}>
              <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                <Typography variant={'subtitle2'}>{label}</Typography>
                <DebouncedTextInput
                  id={name}
                  size={'small'}
                  variant='outlined'
                  name={name}
                  initialValue={formik.values[name]}
                  onChange={formik.handleChange}
                  isValidating={formik.isValidating}
                  onBlur={formik.handleBlur}
                  error={getIn(formik.errors, name)}
                  helperText={getIn(formik.errors, name)}
                  placeholder={placeholder}

                />
              </Stack>
              <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
            </Stack>)

          default:
            return (
              <Stack key={param.name} direction={"row"} spacing={4}>
                <Stack spacing={1} key={name} sx={{width: 600, minWidth: 600}}>
                  <Typography variant={'subtitle2'}>{label}</Typography>
                  <TextField
                    multiline
                    maxRows={10}
                    id={name}
                    size={'small'}
                    variant='outlined'
                    name={name}
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    helperText={fError}
                    placeholder={placeholder}
                  />
                </Stack>
                <Typography variant={"body2"} sx={{maxWidth: 600, pt: 3, opacity: .64}}>{helperText}</Typography>
              </Stack>
            );
        }
      }
    )
  }

  const renderGroups = (groups) => {
    return groups.map(group => {

      return <Accordion key={group.group_name}
                        style={{boxShadow: "none"}}
                        disableGutters square
                        defaultExpanded={!group.default_collapsed}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{group.group_name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2} key={'ad1'}>
            {group.fields && renderFields(group.fields)}
          </Stack>
        </AccordionDetails>
      </Accordion>
    });
  }

  return (
    <FormikProvider value={formik}>
      <Stack sx={{ml: 6}} spacing={2}>
        <Stack sx={{mt: 1, mb: 2}}>
          {template && contract && renderGroups(contract)}
        </Stack>
        <LoadingButton
          variant={"contained"}
          sx={{maxWidth: 120}}
          startIcon={<FontAwesomeIcon style={{marginLeft: 4, fontSize: 16}} icon={faPlay}/>}
          loading={formik.isSubmitting}
          disabled={!formik.isValid}
          onClick={() => formik.submitForm()}>
          {t('run job')}
        </LoadingButton>
      </Stack>
    </FormikProvider>
  );
};
