import {closePopup} from '@biostrand/components/src/popup/popupsSlice';
import {Accordion, AccordionDetails, AccordionSummary, Button, LinearProgress, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {InfoField} from './InfoField';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useJobType} from "./useJobType";

interface Props {
  jobTypeId: number | string;
  popupKey: string;
}

const JobInfoPopup = (props: Props): JSX.Element => {
  const {jobTypeId, popupKey} = props;
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const [jobTypeInfo, isLoading] = useJobType(jobTypeId);

  const onClose = () => {
    dispatch(closePopup(popupKey));
  };

  const renderGroups = (groups) => {
    return groups.map(group => {

      return <Accordion key={group.group_name}
                        style={{boxShadow: "none"}}
                        disableGutters square
                        defaultExpanded={!group.default_collapsed}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>{group.group_name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {group.fields && group.fields.map(inputItem => (
            <Stack key={inputItem.name} direction={'row'} spacing={1}>
              <Typography sx={{minWidth: 170, maxWidth: 170, wordBreak: "break-all"}}
                          variant={"subtitle2"}>{inputItem.label ? inputItem.label : inputItem.name}</Typography>
              <Typography
                variant={"body2"}>{[inputItem.description, inputItem.type, inputItem.required ? '*required' : null].filter(i => !!i).join(', ')}</Typography>
            </Stack>
          ))}
        </AccordionDetails>
      </Accordion>
    });
  }


  return (
    <Stack
      direction={'column'}
      spacing={1}
      sx={{overflowY: 'auto', flex: 1, pt: 1, pb: 1, minWidth: 400, maxWidth: 1000}}>
      <Stack sx={{overflowY: 'auto', flex: 1}} direction={'column'} spacing={1}>

        {isLoading && <LinearProgress/>}

        {jobTypeInfo && <InfoField name={t('Name')} value={jobTypeInfo.name || 'none'}/>}
        {jobTypeInfo && jobTypeInfo.description && (
          <InfoField name={t('Description')} value={jobTypeInfo.description}/>
        )}

        {jobTypeInfo?.contract?.groups && renderGroups(jobTypeInfo?.contract.groups)}
      </Stack>

      <Stack sx={{pt: 2}} direction='row' justifyContent='center' spacing={2}>
        <Button
          onClick={() => {
            onClose();
          }}
          variant='outlined'
          color='primary'>
          {t('Close')}
        </Button>
      </Stack>
    </Stack>
  );
};

export default JobInfoPopup;
