import {ProtoJobType} from "@biostrand/biostrandapi/javascript/dist/JobManagerApi";
import {AppWithJobState} from "./jobsTypes";
import {JobsHistoryItem} from "./jobsGrid/jobsGridColumnUtils";

export const jobTypesSelector = (state: AppWithJobState):  ProtoJobType[] | undefined => state.jobs.jobTypes;
export const jobTypeGroupsSelector = (state: AppWithJobState):  ProtoJobType[] | undefined => state.jobs.jobTypeGroups;
export const isJobTypesLoadingSelector = (state: AppWithJobState):  boolean => state.jobs.isJobTypesLoading;

export const jobRunsSelector = (state: AppWithJobState): JobsHistoryItem[] | undefined => state.jobs.jobRuns;
export const isJobRunsLoadingSelector = (state: AppWithJobState):  boolean => state.jobs.isJobRunsLoading;
